import React from "react";
import css from './digitalDeliveryNote.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_digital_delivery_note"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DigitalDeliveryNote = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Platform.sections.digitalDeliveryNote.title;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.title}>Digital Delivery Note</div>
                <div className={css.imageSectionWrapper}>
                    <div className={[css.imageWrapper, css.img1].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'marked_log_on_the_trailer.png', 'marked log on the trailer')}
                    </div>
                    <div className={[css.imageWrapper, css.img2].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'digital_delivery_note.png', 'digital delivery note')}
                    </div>
                    <div className={[css.imageWrapper, css.img3].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'alphanumeric_marking_of_logs_on_the_trailer.png', 'alphanumeric marking of logs on the trailer')}
                    </div>
                </div>
                <div className={css.text}>
                    <span className={css.textBold}>{title}</span> is a digital delivery document generated
                    on the first shipment from the
                    forest. This document contains logistics data, vehicle data, optional delivery route data in the
                    form of a GPS report, photo documentation, including the application of a code tag on at least one
                    log.
                </div>
            </div>
        </div>
    );
};

export default DigitalDeliveryNote;
