import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './timberIDAuthentication.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";


const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_timberID_authentication"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const TimberIDAuthentication = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Platform.sections.timberIDAuthentication.title;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.content}>
                <div className={css.title}>{title}</div>
                <div className={css.bigContent}>
                    {renderImageByFileName(imagesFluid, 'TimberID-Authentication.png', 'TimberID Authentication')}
                </div>
                <div className={css.smallContent}>
                    {renderImageByFileName(imagesFluid, 'TimberID-Authentication_mobile.png', 'TimberID Authentication mobile')}
                </div>
                <div className={css.text}>
                    The authentication process is an AI-driven deep-tech solution, based on geo-location provenance
                    proof.
                </div>
            </div>
        </div>
    );
};

export default TimberIDAuthentication;
