import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './transactionDDSNumber.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";


const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_transaction_dds_number"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const TransactionDDSNumber = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Platform.sections.transactionDDSNumber.title;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.content}>
                <div className={css.title}>{title}</div>
                <div className={css.bigContent}>
                    {renderImageByFileName(imagesFluid, 'Transaction-DDS-Number.png', 'Transaction DDS Number')}
                </div>
                <div className={css.text}>
                    TimberID Platform automated workflow simplifies collecting and handling information, keeps data
                    private, and shares only what's necessary.
                    <br/><br/>
                    It leads to submitting data efficiently to the EU's "Traces" Information System.
                </div>
            </div>
        </div>
    );
};

export default TransactionDDSNumber;
