import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './timberIDBusinessIntelligenceAndReporting.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import WideSection from "../../shared/WideSection/WideSection";
import {getRoutes} from "../../shared/routes";


const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_timberID_business_intelligence_and_reporting"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const TimberIDBusinessIntelligenceAndReporting = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Platform.sections.timberIDBusinessIntelligenceReporting.title;

    const content =
        <div className={css.row}>
            <div className={css.image}>
                {renderImageByFileName(imagesFluid, 'TimberID-1.png', 'TimberID 1')}
                {renderImageByFileName(imagesFluid, 'TimberID-2.png', 'TimberID 2')}
            </div>
            <div className={css.image}>
                {renderImageByFileName(imagesFluid, 'TimberID-3.png', 'TimberID 3')}
                {renderImageByFileName(imagesFluid, 'TimberID-4.png', 'TimberID 4')}
            </div>
        </div>
    return (
        <WideSection
            title={title}
            bigContent={<div className={css.bigContent}>
                {content}
            </div>}
            smallContent={<div className={css.smallContent}>
                {content}
            </div>}
        />
    );
};

export default TimberIDBusinessIntelligenceAndReporting;
