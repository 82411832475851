import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import SafeAndEffectiveEUDRExecution
    from "../components/sections/SafeAndEffectiveEUDRExecution/SafeAndEffectiveEUDRExecution";
import css from "./platform.module.scss";
import Logo from "../images/logos/TimberID_Platform_logo.svg";
import DueDiligenceStatementCreation
    from "../components/sections/DueDiligenceStatementCreation/DueDiligenceStatementCreation";
import TransactionDDSNumber from "../components/sections/TransactionDDSNumber/TransactionDDSNumber";
import TimberIDAuthentication from "../components/sections/TimberIDAuthentication/TimberIDAuthentication";
import TimberIDBusinessIntelligenceAndReporting
    from "../components/sections/TimberIDBusinessIntelligenceAndReporting/TimberIDBusinessIntelligenceAndReporting";
import DeforestationGeoSpatialData
    from "../components/sections/DeforestationGeoSpatialData/DeforestationGeoSpatialData";
import DigitalDeliveryNote from "../components/sections/DigitalDeliveryNote/DigitalDeliveryNote";

const Platform = () => {
    const routes = getRoutes().Platform;
    const pageTitle = routes.pageTitle;


    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>
                <div className={css.logo}><img src={Logo} alt="eudr"/></div>
            </PageTitle>

            <SafeAndEffectiveEUDRExecution/>

            <DueDiligenceStatementCreation/>

            <TransactionDDSNumber/>

            <DeforestationGeoSpatialData/>

            <DigitalDeliveryNote/>

            <TimberIDAuthentication/>

            <TimberIDBusinessIntelligenceAndReporting/>

            <AdditionalInformation/>

            <Footer/>

        </Layout>
    );
};

export default Platform;
