import React from "react";
import css from './deforestationGeoSpatialData.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";
import {getRoutes} from "../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_deforestation_geoSpatial_data"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DeforestationGeoSpatialData = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Platform.sections.deforestationGeoSpatialData.title;

    const renderSection = (index, imgScale = 1, imageAlt, text, image, doubleSize = false) => {
        return (
            <div className={css.section}>
                <div className={css.sectionContentWrapper}>
                    <div className={css.sectionImageWrapper} style={{width: `${100 * imgScale}%`}}>
                        {renderImageByFileName(imagesFluid, image, imageAlt)}
                    </div>
                    <div className={css.sectionText}>
                        {text}
                    </div>
                </div>
            </div>
        );
    };

    const calcHeight = width => width * 589 / 824;

    const renderImageBitmap = (filename, alt = '', width = 280) => {
        return <div className={css.imageWrapper} style={{width, height: calcHeight(width)}}>
            {renderImageByFileName(imagesFluid, filename, alt)}
        </div>;
    }

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.title}>{title}</div>
                <div className={css.textBold}>Collection options of plot of land polygon data</div>
                <div className={css.sectionsWrapper}>
                    {renderSection(1, 0.8, "GIS", "Automatically imported from dozens of standardised GIS (Geographic Information Systems) file formats (like: Shapefiles, GeoJSON, Geodatabase, XML, JSON, TXT, SQL, etc.)", "GIS.png")}
                    {renderSection(1, 1, "geospatial", "Automatically imported from open and well documented geospatial and other file formats (like: contour lines, raster and vectors data, triangulated networks, lidars data etc.)", "geospatial.png")}
                    {renderSection(1, 1, "forest map Timberid", "Manually by simple drawing polygon on map in web user interface", "forest_map_timberid.png")}
                    {renderSection(1, 0.9, "GPS coordinates", "Manually by using data collected via mobile apps using GPS coordinates in field", "GPS-coordinates.png")}
                </div>
                <div className={css.textBold}>Additional information based on plot’s geo-spatial data:</div>
                <div className={css.text}>
                    Deforestation analysis and visual reports <br/>
                    Carbon Footprint Monitoring, predictions and data reports
                </div>
                <div className={css.imageSectionWrapper}>
                    {renderImageBitmap('Deforestation_2017.png', 'deforestation 2017')}
                    {renderImageBitmap('Deforestation_2018.png', 'deforestation 2018')}
                    {renderImageBitmap('Deforestation_2019.png', 'deforestation 2019')}
                </div>
            </div>
        </div>
    );
};

export default DeforestationGeoSpatialData;
