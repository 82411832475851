import React from "react";
import css from './safeAndEffectiveEUDRExecution.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import SafeAndEffectiveEUDRExecutionImage
    from "../../../images/sections/SafeAndEffectiveEUDRExecution/Platform_hero.png";
import ListItem from "../../shared/ListItem/ListItem";

const SafeAndEffectiveEUDRExecution = () => {

    const title = '';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={SafeAndEffectiveEUDRExecutionImage} alt={'Safe And Effective EUDR Execution'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Safe And Effective EUDR Execution
                    <br/><br/>
                    <div>
                        <ListItem
                            list={['Data security and privacy, selectively release of data', 'Documented risk assessment and mitigation measures', 'Accumulated geolocation data',
                                'Verifiability of due diligence efforts of upstream suppliers', 'Segregation of wood with non-compliances', ' API seamless integration with EU Information System'
                            ]}/>
                    </div>
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default SafeAndEffectiveEUDRExecution;
